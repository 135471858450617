import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import Template from "../components/Template"

export default ({ pageContext }) => {
  const { allPosts } = pageContext

  const allPostsSorted = allPosts.sort((a, b) => {
    const aDate = new Date(a.published_date)
    const bDate = new Date(b.published_date)
    if(aDate < bDate) return 1
    if(bDate < aDate) return -1
    return 0
  })

  return (
    <Template title="Blog">
      {allPostsSorted.map(post => (
        <Link
          key={post.slug}
          to={`/blog/${post.slug}/`}
          css={css`
            text-decoration: none;
            padding: 32px 0;
            display: block;
            border-bottom: 2px solid var(--accent-100);

            :last-child {
              border-bottom: none;
            }

            :first-of-type {
              padding-top: 0;
            }
          `}
        >
          <article
            css={css`
              
            `}
          >
            <h1
              css={css`
                color: var(--text-500);
                margin: 0;
                margin-bottom: 16px;
              `}
            >
              {post.title}
            </h1>

            <p
              css={css`
                text-decoration: underline;
                color: var(--text-300);
                font-weight: 600;
                margin: 0;
              `}
            >
              Click to read
            </p>
          </article>
        </Link>
      ))}
    </Template>
  )
}